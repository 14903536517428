import React, { ReactElement } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./LoadingBlock.module.scss";

interface Props {
  size?: number;
  className?: string;
}

export default function LoadingBlock({
  size = 50,
  className,
}: Props): ReactElement {
  return (
    <div
      className={className ?? styles.loadingContainer}
      style={{
        fontSize: size,
      }}
    >
      <LoadingOutlined />
    </div>
  );
}
