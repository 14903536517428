import { useState } from "react";
import { useUser } from "components/UserContext/UserContext";
import BrokerageLogin from "./BrokerageLogin";

import PlusIcon from "/public/icons/plus.svg";
import styles from "./Brokerages.module.scss";
import classNames from "classnames";
import ConnectBrokerageModal from "components/PlaidLink/ConnectBrokerageModal/ConnectBrokerageModal";

type Props = {
  removeTitle?: boolean;
  className?: string;
  removeButton?: boolean;
};

const Brokerages = (props: Props) => {
  const userContext = useUser();
  const [openBrokerageConnect, setOpenBrokerageConnect] =
    useState<boolean>(false);
  const [expandedLoginIndex, setExpandedLoginIndex] = useState<
    number | undefined
  >(undefined);

  return (
    <div className={styles.Brokerages}>
      {!props.removeTitle && (
        <div>
        <h3 className={styles.Brokerages__heading}>Brokerages</h3>
        <p className={styles.Brokerages__disclaimer}>IMPORTANT: Brokerage data refreshes nightly. Please allow 24 hours for new holdings to sync with TiiCKER.</p>
        </div>
      )}
      <div className={classNames(styles.Brokerages__content, props.className)}>
        <div className={styles.Brokerages__content__heading}>
          <div className={styles.Brokerages__content__heading__broker}>
            Broker
          </div>
          <div className={styles.Brokerages__content__heading__accounts}>
            Accounts
          </div>
          <div className={styles.Brokerages__content__heading__value}>
            Value
          </div>
          <div className={styles.Brokerages__content__heading__actions}></div>
        </div>
        {userContext.state.logins.map((login, loginIndex) => {
          return (
            <BrokerageLogin
              key={`brokerage-${login.id}`}
              login={login}
              loginIndex={loginIndex}
              isExpanded={expandedLoginIndex === loginIndex}
              onSelect={setExpandedLoginIndex}
            />
          );
        })}
        {!props.removeButton && (
          <>
            <div
              className={styles.Brokerages__content__addBrokerage}
              onClick={() => setOpenBrokerageConnect(true)}
            >
              <span className={styles.Brokerages__content__addBrokerage__label}>
                Add Another Brokerage
              </span>
              <PlusIcon />
            </div>
            <ConnectBrokerageModal
              visible={openBrokerageConnect}
              onClose={() => setOpenBrokerageConnect(false)}
              openPlaid={() => userContext.openPlaid()}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Brokerages;
