import { GetPassivOAuthRedirect } from "$gql/mutations/general/GetPassivOAuthRedirect.gen";
import { GetPassivBrokerages } from "$gql/queries/general/GetPassivBrokerages.gen";
import classNames from "classnames";
import Brokerages from "components/MyAccount/Brokerages";
import { useUser } from "components/UserContext/UserContext";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useMutationBundle, useQueryBundle } from "source/hooks";
import BrokerageCard from "./BrokerageCard";
import styles from "./BrokerageSection.module.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export type PageState = "choose" | "launchOAuth" | "redirect";

type Props = {
  openTradier: () => void;
  openPlaid: () => void;
  openQuestrade: () => void;
  removeTitle?: boolean;
  onboarding?: boolean;
  forceSelect?: boolean;
  hidePlaid?: boolean;
};

const BrokerageSection = (props: Props) => {
  const userContext = useUser();
  const router = useRouter();

  const [pageState, setPageState] = useState<PageState>("launchOAuth");
  const passivBrokerages = useQueryBundle(GetPassivBrokerages);
  const [getPassivOAuthRedirect] = useMutationBundle(GetPassivOAuthRedirect);
  const MySwal = withReactContent(Swal);

  const showAlert = (message, error = null) => {
    // Construct the base mailto link
    let mailtoLink = `mailto:service@tiicker.com?subject=User Session Problem`;

    // If there's an error object, include it in the email body
    if (error) {
      const errorDetails = encodeURIComponent(
        `Error details:\n\n${JSON.stringify(error, null, 2)}`
      );
      mailtoLink += `&body=${errorDetails}`;
    }

    MySwal.fire({
      title: "Our Apologies!",
      html: `${message} Please <a href="${mailtoLink}" target="_blank">contact us</a> so we can help fix the issue.`,
      icon: "error",
      confirmButtonText: "Close",
    });
  };

  return (
    <>
      {userContext.state.logins.length === 0 || !props.onboarding ? (
        <div className={styles.brokerageSection}>
          <BrokerageCard
            imageUrl="/images/TradierLogo.png"
            key={`brokerage-tradier`}
            onClick={props.openTradier}
            cardClassName={styles.brokerageContainer}
            brokerageName="Tradier"
          />
          <BrokerageCard
            imageUrl="/images/Questrade-Logo.svg"
            key={`brokerage-questrade`}
            onClick={props.openQuestrade}
            cardClassName={styles.brokerageContainer}
            brokerageName="Questrade"
          />
          {passivBrokerages.state === "DONE" &&
            passivBrokerages.data.getPassivBrokerages.map((pb) => {
              return (
                <BrokerageCard
                  imageUrl={pb.squareLogo ?? pb.logo ?? ""}
                  key={`brokerage-${pb.id}`}
                  onClick={() => {
                    if (userContext.state.userId) {
                      getPassivOAuthRedirect({
                        variables: {
                          userId: userContext.state.userId,
                          brokerageSlug: pb.slug,
                        },
                      })
                        .then((response) => {
                          const uri = response.data?.getPassivOAuthRedirect;
                          if (uri) {
                            router.replace(uri);
                          } else {
                            // Handle the case where the URI is not present in the response
                            showAlert(
                              "We could not complete your request at this time."
                            );
                          }
                        })
                        .catch((error) => {
                          // Handle any errors from the getPassivOAuthRedirect call
                          console.error(
                            "Error fetching OAuth redirect URI:",
                            error
                          );
                          showAlert(
                            "We encountered a problem with this brokerage connector service.",
                            error
                          );
                        });
                    } else {
                      // Show the alert if userId is not present
                      showAlert(
                        "We encountered a slight problem with your user session."
                      );
                    }
                  }}
                  cardClassName={styles.brokerageContainer}
                  brokerageName={pb.name}
                />
              );
            })}
          {!props.hidePlaid && (
            <BrokerageCard
              imageUrl="/images/PlaidLogo.png"
              key="brokerage-plaid"
              onClick={props.openPlaid}
              cardClassName={classNames(
                styles.brokerageContainer,
                styles.brokerageContainer_plaid
              )}
              logoClassName={styles.plaidLogo}
              dataCy="PlaidConnectionCard"
            >
              <p className={styles.plaidText}>{`Can't find your brokerage?`}</p>
              <p className={styles.plaidText}>Connect with Plaid!</p>
            </BrokerageCard>
          )}
        </div>
      ) : (
        <div className={styles.connectAccountsSection}>
          {!props.removeTitle && <h5>Connected Accounts</h5>}
          <Brokerages removeTitle className={styles.brokerages} />
        </div>
      )}
    </>
  );
};

export default BrokerageSection;
