import { Institution } from "$gql/types.gen";
import classNames from "classnames";
import { Media } from "components/Media/media";
import Modal from "components/Modal/Modal";
import Brokerages from "components/MyAccount/Brokerages";
import BrokerageSection from "components/Onboarding/Steps/ConnectAccounts/BrokerageSection";
import { useUser } from "components/UserContext/UserContext";
import React, { useState } from "react";
import styles from "./ConnectBrokerageModal.module.scss";
import LaunchOAuth from "./LaunchOAuth/LaunchOAuth";
import SecuritySection from "./SecuritySection/SecuritySection";

type Props = {
  visible: boolean;
  onClose: () => void;
  openPlaid: (institutionLoginId?: number | undefined) => void;
  startingState?: Institution | "select-brokerage";
  onboarding?: boolean;
  hidePlaid?: boolean;
};

const ConnectBrokerageModal = (props: Props) => {
  const userContext = useUser();
  const [state, setState] = useState<"select-brokerage" | Institution>(
    props.startingState ?? "select-brokerage"
  );
  const [brokerageName, setBrokerageName] = useState<string>("");

  const close = () => {
    setState("select-brokerage");
    props.onClose();
  };

  const backStep = () => {
    if (props.startingState) {
      props.onClose();
    }
    setState("select-brokerage");
  };

  const selectTd = () => {
    setState(Institution.Tdameritrade);
    setBrokerageName("TD Ameritrade");
  };

  const selectTradier = () => {
    setState(Institution.Tradier);
    setBrokerageName("Tradier");
  };

  const selectQuestTrade = () => {
    setState(Institution.Questrade);
    setBrokerageName("Questrade");
  };

  const selectPlaid = () => {
    props.openPlaid();
    props.onClose();
  };

  const brokerageInstructions = props.hidePlaid
    ? "Select your brokerage"
    : "Select your brokerage or use Plaid to connect most other brokerages.";

  return (
    <Modal
      visible={props.visible}
      onClose={close}
      fullWidthSmall
      modalClassName={styles.modal}
    >
      <div className={styles.container}>
        <div
          className={classNames(
            styles.infoContainer,
            state !== "select-brokerage" && styles.paddedModal
          )}
        >
          <h3>Connect your trading accounts</h3>
          <p>
            {state === "select-brokerage"
              ? brokerageInstructions
              : `You are about to be transferred to ${brokerageName} in order to authenticate your account. Note: We do not store any credentials and ${brokerageName} is in full control of how we access your account.`}
          </p>
          <Media greaterThanOrEqual="md">
            <SecuritySection />
          </Media>
        </div>
        {state === "select-brokerage" && (
          <div className={styles.brokerageContainer}>
            <div className={styles.brokerages}>
              <BrokerageSection
                openTradier={selectTradier}
                openPlaid={selectPlaid}
                openQuestrade={selectQuestTrade}
                removeTitle
                onboarding={props.onboarding}
                hidePlaid={props.hidePlaid}
              />
            </div>
          </div>
        )}
        {state !== "select-brokerage" && (
          <div className={styles.paddedModal}>
            <LaunchOAuth institution={state} backStep={backStep} />
          </div>
        )}
      </div>
      <Media lessThan="md" className={styles.securitySection}>
        <SecuritySection />
      </Media>
    </Modal>
  );
};

export default ConnectBrokerageModal;
