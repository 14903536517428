import { Institution } from "$gql/types.gen";
import Button from "components/core/Button/Button";
import RedirectToOAuth from "components/PlaidLink/RedirectToOAuth";
import React, { useState } from "react";
import styles from "./LaunchOAuth.module.scss";

type Props = {
  institution: Institution;
  backStep: () => void;
};

const LaunchOAuth = (props: Props) => {
  const [redirect, setRedirect] = useState<boolean>(false);

  const imgUrl =
    props.institution === Institution.Tdameritrade
      ? "/images/TD-Ameritrade.png"
      : props.institution === Institution.Tradier
      ? "/images/TradierLogo.png"
      : props.institution === Institution.Questrade
      ? "/images/Questrade-Logo.svg"
      : "";
  const brokerUrl =
    props.institution === Institution.Tdameritrade
      ? "https://www.tdameritrade.com"
      : props.institution === Institution.Tradier
      ? "https://www.tradier.com"
      : props.institution === Institution.Questrade
      ? "https://www.questrade.com"
      : "";
  const newAccountUrl =
    props.institution === Institution.Tradier
      ? "https://brokerage.tradier.com/signup/getting-started"
      : props.institution === Institution.Tdameritrade
      ? "https://start.tdameritrade.com/select?entity=103"
      : props.institution === Institution.Questrade
      ? "https://www.questrade.com/account-selection"
      : "";

  const connect = () => {
    setRedirect(true);
  };

  return (
    <div className={styles.container}>
      <a target="_blank" href={brokerUrl} className={styles.brokerLink}>
        <img src={imgUrl} className={styles.brokerLogo} alt="Broker Logo" />
      </a>
      <div className={styles.actionContainer}>
        <div className={styles.buttonContainer}>
          <Button type="inline" onClick={props.backStep}>
            Back
          </Button>
          <Button onClick={connect}>Connect</Button>
        </div>
        <a target="_blank" href={newAccountUrl} className={styles.noAccount}>
          Don't have an account?
        </a>
      </div>
      {redirect && <RedirectToOAuth institution={props.institution} />}
    </div>
  );
};

export default LaunchOAuth;
