import Modal from "components/Modal/Modal";
import Button from "../Button/Button";

import styles from "./ConfirmationModal.module.scss";

interface Props {
  title: string;
  description: string;
  closeText?: string;
  confirmText?: string;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = (props: Props) => {
  const {
    title,
    description,
    closeText,
    confirmText,
    onConfirm,
    ...modalProps
  } = props;

  return (
    <Modal {...modalProps} width={650}>
      <div className={styles.ConfirmationModal}>
        <h2 className={styles.ConfirmationModal__title}>{title}</h2>
        <p className={styles.ConfirmationModal__description}>{description}</p>
        <div className={styles.ConfirmationModal__actions}>
          <Button type="secondary" onClick={props.onClose}>
            {closeText ?? "Close"}
          </Button>
          <Button type="primary" onClick={onConfirm}>
            {confirmText ?? "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
