import React from "react";
import { useQueryBundle } from "source/hooks";
import {
  GetOAuthUrlForNewLogin,
  Institution,
} from "$gql/queries/tradier/GetOAuthUrlForNewLogin.gen";
import Cookies from "js-cookie";

interface Props {
  institution: Institution;
}

const RedirectToOAuth = (props: Props) => {
  const brokerPopupUrlQuery = useQueryBundle(GetOAuthUrlForNewLogin, {
    variables: {
      redirectUrl: window ? window.location.pathname : `/`,
      institution: props.institution,
    },
  });

  if (brokerPopupUrlQuery.state !== "DONE") {
    return <p>Loading</p>;
  }

  const bundle = brokerPopupUrlQuery.data.trading.getOAuthUrlForNewLogin;

  Cookies.set("oAuthState", bundle.state);
  window.location.href = bundle.url;

  return <>Redirecting</>;
};

export default RedirectToOAuth;
