import classNames from "classnames";
import React from "react";
import styles from "./BrokerageCard.module.scss";

type Props = {
  imageUrl: string;
  onClick: () => void;
  key: string;
  brokerageName?: string;
  cardClassName?: string;
  logoClassName?: string;
  children?: React.ReactNode;
  dataCy?: string;
};

const BrokerageCard = (props: Props) => {
  return (
    <div
      className={classNames(styles.card, props.cardClassName)}
      key={props.key}
      onClick={props.onClick}
      data-cy={props.dataCy}
    >
      <img
        src={props.imageUrl}
        className={classNames(styles.brokerageLogo, props.logoClassName)}
      />
      {props.brokerageName && (
        <p className={styles.brokerageName}>{props.brokerageName}</p>
      )}
      <div className={styles.childContainer}>{props.children}</div>
    </div>
  );
};

export default BrokerageCard;
