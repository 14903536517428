import React from "react";
import { useQueryBundle } from "source/hooks";
import { Institution } from "$gql/queries/tradier/GetOAuthUrlForNewLogin.gen";
import Cookies from "js-cookie";
import { GetOauthUrlForBroker } from "$gql/queries/tradier/GetOauthUrlForBroker.gen";

interface Props {
  loginId: number;
}

const RedirectToFixOAuth = (props: Props) => {
  const brokerPopupUrlQuery = useQueryBundle(GetOauthUrlForBroker, {
    variables: {
      institutionLoginId: props.loginId,
      redirectUrl: window ? window.location.pathname : `/`,
    },
  });

  if (brokerPopupUrlQuery.state !== "DONE") {
    return <p>Loading</p>;
  }

  const bundle = brokerPopupUrlQuery.data.trading.getOAuthUrlForExistingLogin;

  Cookies.set("oAuthState", bundle.state);
  window.location.href = bundle.url;

  return <>Redirecting</>;
};

export default RedirectToFixOAuth;
