import { formatCurrency } from "@tiicker/util/lib/formats";
import styles from "./BrokerageAccount.module.scss";

interface Props {
  accountName: string;
  accountNumber: string;
  cashValue: number;
  stockValue: number;
  otherInvestmentsValue: number;
}

const BrokerageAccount = (props: Props) => {
  const totalAccountValue =
    (props.cashValue || 0) +
    (props.stockValue || 0) +
    (props.otherInvestmentsValue || 0);

  return (
    <div className={styles.BrokerageAccount__grid}>
      <div>
        <div className={styles.BrokerageAccount__label}>Account Name</div>
        <div>{props.accountName}</div>
      </div>
      <div>
        <div className={styles.BrokerageAccount__label}>Account Number</div>
        <div>{`• • • • ${props.accountNumber}`}</div>
      </div>
      <div>
        <div className={styles.BrokerageAccount__label}>
          Total Account Value
        </div>
        <div>{formatCurrency(totalAccountValue)}</div>
      </div>
      <div>
        <div className={styles.BrokerageAccount__label}>Stocks</div>
        <div>{formatCurrency(props.stockValue || 0)}</div>
      </div>
      <div>
        <div className={styles.BrokerageAccount__label}>Cash</div>
        <div>{formatCurrency(props.cashValue || 0)}</div>
      </div>
      <div>
        <div className={styles.BrokerageAccount__label}>
          Other Priced Investments
        </div>
        <div>{formatCurrency(props.otherInvestmentsValue || 0)}</div>
      </div>
    </div>
  );
};

export default BrokerageAccount;
