import React from "react";
import styles from "./SecuritySection.module.scss";
import Shield from "/public/icons/shield.svg";
import Lock from "/public/icons/lock.svg";

type Props = {};

const SecuritySection = (props: Props) => {
  return (
    <div className={styles.securitySection}>
      <div className={styles.securityMessage}>
        <Shield className={styles.securityIcon} />
        <div>
          <h5 className={styles.securityTitle}>Secure</h5>
          <p className={styles.securityDetail}>
            Transfer of your information is encrypted end-to-end
          </p>
        </div>
      </div>

      <div className={styles.securityMessage}>
        <Lock className={styles.securityIcon} />
        <div>
          <h5 className={styles.securityTitle}>Private</h5>
          <p className={styles.securityDetail}>
            Your credentials will never be made accessible to TiiCKER
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecuritySection;
